<script lang="ts" context="module">
    import type {RadioChangeEventDetail} from "./controls/Radio.svelte";
    import type {SupportedLocale} from "../types";

    export interface LanguagePickerRadiosProps {
        entries: {
            href: string;
            label: string;
            locale: SupportedLocale;
        }[];
        selectedLocale: string;
    }
</script>

<script lang="ts">
    import RadioButton from "./controls/RadioButton.svelte";
    import IconFaGlobeSolid from "./icons/IconFaGlobeSolid.svelte";

    export let entries: LanguagePickerRadiosProps["entries"];
    export let selectedLocale: LanguagePickerRadiosProps["selectedLocale"];

    function handleRadioChange(evt: CustomEvent<RadioChangeEventDetail>) {
        if (evt.detail.checked) {
            document.location.href = evt.detail.data;
        }
    }
</script>

<div class="language-picker-buttons">
    <div class={"icon-globe"}>
        <IconFaGlobeSolid />
    </div>
    {#each entries as { href, locale, label } (locale)}
        <RadioButton
            checked={locale === selectedLocale}
            class="button"
            color="light"
            data={href}
            name="lang"
            on:change={handleRadioChange}
            value={locale}
        >
            {label.toUpperCase()}
        </RadioButton>
    {/each}
</div>

<style>
    .language-picker-buttons {
        align-items: center;
        gap: var(--size-4);
        display: flex;
        flex-wrap: wrap;
    }

    .icon-globe {
        display: flex;
        align-items: center;
        color: var(--color-shade-50);
        font-size: var(--font-size-2xl);
    }
</style>
