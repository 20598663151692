<script lang="ts" context="module">
    export interface RadioChangeEventDetail {
        checked: boolean;
        data?: any;
    }

    export interface RadioButtonProps {
        name: string;
        id?: string;
        checked?: boolean;
        data?: any;
        value?: string;

        class?: string;
        color?: "primary" | "secondary" | "tertiary" | "light" | "dark";
        disabled?: boolean;
        label?: string;
        size?: "sm" | "md" | "lg" | "xl";
    }
</script>

<script lang="ts">
    import {combineClasses} from "@utils/component-utils";

    import {createEventDispatcher} from "svelte";

    let className: RadioButtonProps["class"] = undefined;
    export {className as class};

    export let name: RadioButtonProps["name"];
    export let value: RadioButtonProps["value"] = undefined;
    export let id: RadioButtonProps["id"] = undefined;
    export let checked: RadioButtonProps["checked"] = false;
    export let data: RadioButtonProps["data"] = undefined;

    export let color: RadioButtonProps["color"] = "primary";
    export let disabled: RadioButtonProps["disabled"] = undefined;
    export let label: RadioButtonProps["label"] = undefined;
    export let size: RadioButtonProps["size"] = undefined;

    const classNames = combineClasses(["base", color, size, className]);

    const dispatch = createEventDispatcher();

    function handleChange(evt: Event) {
        const checked = (evt.target as any).checked;
        dispatch("change", {data, checked});
    }
</script>

<label class={classNames} class:radioButton={true} class:checked>
    <input type="radio" {name} {id} {checked} on:change={handleChange} {value} {disabled} />
    <slot />
    {#if label}{label}{/if}
</label>

<style>
    .radioButton {
        --color-active: var(--color-primary-600);

        border: var(--size-px) solid var(--color-main);
        border-radius: var(--radius-xs);
        box-shadow: var(--shadow-sm);
        box-sizing: border-box;
        cursor: pointer;
        display: inline-flex;
        font-family: var(--font-sans);
        font-weight: var(--font-weight-bold);
        justify-content: center;
        line-height: var(--leading-tight);
        padding: var(--size-2-5) var(--size-4);
        text-decoration: none;
        transition: all ease-out 100ms;
        user-select: none;

        /* Disable tab highlight color on mobile devices */
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
    }

    .radioButton:has(input[type="radio"]:focus-visible) {
        outline: var(--focus-outline);
    }

    .radioButton:has(input[type="radio"]:disabled) {
        cursor: auto;
        opacity: 0.5;
    }
    .radioButton:not(:disabled):active {
        transform: scale(0.9);
    }

    /** CHECKED */
    .radioButton.checked {
        background: var(--color-main);
        border-color: var(--color-main);
        color: var(--color-contrast);
    }
    .radioButton.checked:hover {
        background: var(--color-interact);
        border-color: var(--color-interact);
    }

    /** UNCHECKED */
    .radioButton:not(.checked) {
        background: transparent;
        border-color: var(--color-main);
        color: var(--color-main);
    }
    .radioButton:not(.checked):hover {
        background: var(--color-interact);
        border-color: var(--color-interact);
        color: var(--color-contrast);
    }

    /* COLORS */
    .primary {
        --color-main: var(--color-primary);
        --color-contrast: var(--color-shade-50);
        --color-interact: var(--color-primary-400);
    }
    .secondary {
        --color-main: var(--color-secondary);
        --color-contrast: var(--color-shade-50);
        --color-interact: var(--color-secondary-400);
    }
    .tertiary {
        --color-main: var(--color-tertiary);
        --color-contrast: var(--color-shade-50);
        --color-interact: var(--color-tertiary-400);
    }
    .light {
        --color-main: var(--color-shade-50);
        --color-contrast: var(--color-shade-900);
        --color-interact: var(--color-shade-100);
    }
    .dark {
        --color-main: var(--color-shade-900);
        --color-contrast: var(--color-shade-50);
        --color-interact: var(--color-shade-800);
    }

    /* SIZES */
    .sm {
        font-size: var(--font-size-md);
    }
    .md {
        font-size: var(--font-size-md);
    }
    .lg {
        font-size: var(--font-size-lg);
    }
    .xl {
        font-size: var(--font-size-xl);
    }

    /** INPUT STYLES */
    input[type="radio"] {
        appearance: none;
        display: none;
    }

    input[type="radio"]:focus {
        outline: var(--focus-outline);
    }
</style>
